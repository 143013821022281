import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap';

import './offcanvas';
import './smoothScroll';
import './social-icons';

import './aos';

import './scrollspy';

import './watchForHover';

import './browserSync';

