$(document).ready(function() {

	let offset = 150;

	let $navigationLinksDesktop = $('.menu-desktop .menu-item > a');
	let $navigationLinksMobile = $('.menu-mobile .menu-item > a');
	let $sections = $($('.anchor').get().reverse());


	let sectionIdToNavigationLinkDesktop = {};
	let sectionIdToNavigationLinkMobile = {};

	$sections.each(function() {
		let id = $(this).attr('id');
		sectionIdToNavigationLinkDesktop[id] = $('.menu-desktop .menu-item > a[href*="/#' + id + '"]');
		sectionIdToNavigationLinkMobile[id] = $('.menu-mobile .menu-item > a[href*="/#' + id + '"]');
	});



	function highlightNavigation() {

		let scrollPosition = $(window).scrollTop();

		$sections.each(function() {

			let currentSection = $(this);
			let sectionTop = currentSection.offset().top;

			let id = currentSection.attr('id');

			let $navigationLinkDesktop = sectionIdToNavigationLinkDesktop[id];
			let $navigationLinkMobile = sectionIdToNavigationLinkMobile[id];

			if (scrollPosition + offset >= sectionTop) {

				if(!$navigationLinkDesktop.hasClass('active')) {
					$navigationLinksDesktop.removeClass('active');
					$navigationLinkDesktop.addClass('active');
				}
				if(!$navigationLinkMobile.hasClass('active')) {
					$navigationLinksMobile.removeClass('active');
					$navigationLinkMobile.addClass('active');
				}

				return false;
			}
			else {
				$navigationLinkDesktop.removeClass('active');
				$navigationLinkMobile.removeClass('active');
			}
		});
	}

	$(window).scroll(highlightNavigation);
	$(window).trigger('scroll');

});
