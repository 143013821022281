$(document).ready(function($) {

	let btn = $('#social-icons-mobile-toggle-btn');
	let cols = $('.social-icons__col-mobile-hide');

	$(btn).click(toggle);

	function toggle() {

		if ($(btn).hasClass('active')) {
			$(btn).removeClass('active');
			$(cols).removeClass('active');
		} else {
			$(btn).addClass('active');
			$(cols).addClass('active');
		}

	}

});
